<script>
import ge1mastService from '@/services/master/ge1mast.js'

export default {
  props: {
    rowSelected: Object
  },
  data() {
    return {
      ledger: null,
      isDeleteAllow: false,
      message: null,
      isLoading: false
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.fetchLedgerInfo()
    },
    fetchLedgerInfo() {
      // Fetch the information of current ledger from database
      this.setIsDeleteAllow()
    },
    async setIsDeleteAllow() {
      if (!this.rowSelected) return
      try {
        const { data } = await ge1mastService.getDeleteGe1mastPermissionById(
          this.rowSelected.glmaccn_id
        )
        if (data.data === 'ABLE_TO_DELETE') {
          this.isDeleteAllow = true
          this.message = null
        }
      } catch (err) {
        this.isDeleteAllow = false
        this.message = err.response.data.thMessage
      }
    },
    async deleteButtonClicked() {
      this.isLoading = true
      let body = {
        ledgerId: this.rowSelected.glmaccn_id
      }
      try {
        await ge1mastService.deleteGe1mastById(body)
        this.$emit('closeModal', {
          from: 'deleteSuccess',
          content: 'ลบรหัสบัญชีสำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        console.log('=== Delete ledger error ===', err)
        this.$emit('closeModal', {
          from: 'deleteError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 1000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    cancelButtonClicked() {
      this.$emit('closeModal', { from: 'deleteCancel' })
    }
  }
}
</script>

<template>
  <div>
    <!-- have selected row -->
    <div v-if="rowSelected">
      <b-row align-v="start">
        <b-col class="col-2">
          <b-icon
            icon="trash-fill"
            variant="danger"
            class="mt-2"
            style="width: 100%; height: 100%;"
          ></b-icon>
        </b-col>
        <b-col class="col-10">
          <h5>รายละเอียดของรายการบัญชีที่จะลบ</h5>
          <div>
            <div class="d-flex mt-3">
              <span>ชื่อบัญชี:</span>
              <span class="ml-2 font-weight-bold">
                {{ rowSelected.glmaccn_accountName }}
              </span>
            </div>
            <div class="d-flex mt-3">
              <span>ประเภทบัญชี:</span>
              <span class="ml-2 font-weight-bold">
                {{ rowSelected.glmaccn_accountType }}
              </span>
            </div>
            <div class="d-flex mt-3">
              <span>กลุ่มบัญชี:</span>
              <span class="ml-2 font-weight-bold">
                {{ rowSelected.glmaccn_group }}
              </span>
            </div>
            <div class="d-flex mt-3">
              <span>ระดับบัญชี:</span>
              <span class="ml-2 font-weight-bold">
                {{ rowSelected.glmaccn_accountLevel }}
              </span>
            </div>
            <div class="d-flex text-danger">
              <span class="mt-4 font-weight-bold">
                {{ message }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row v-if="!isDeleteAllow" align-h="end">
        <b-col class="col-3">
          <b-button
            class="w-100"
            variant="outline-danger"
            @click="cancelButtonClicked()"
          >
            <span>ยกเลิก</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else align-h="end">
        <b-col class="col-3">
          <b-button
            class="w-100"
            variant="danger"
            @click="deleteButtonClicked()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>ลบ</span>
          </b-button>
        </b-col>
        <b-col class="col-3">
          <b-button
            class="w-100"
            variant="outline-danger"
            @click="cancelButtonClicked()"
            :disabled="isLoading"
          >
            <span>ยกเลิก</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- have no row selected -->
    <div v-else>
      <span>กรุณาเลือกบัญชีที่ต้องการลบ</span>
    </div>
  </div>
</template>

<style></style>
