<script>
import validator from 'validator'
import _ from 'lodash'

import ge1mastService from '@/services/master/ge1mast.js'

export default {
  props: {
    rowSelected: Object,
    typeOptionsProps: Array,
    levelOptionsProps: Array,
    categoryOptionsProps: Array
  },
  data() {
    return {
      isLoading: false,
      id: null,
      accountNumber: null,
      disableAccountNumberInputField: true,
      accountName: null,
      accountType: 1,
      typeOptions: [],
      disableTypeSelectionField: false,
      accountLevel: 1,
      levelOptions: [],
      parent: null,
      disableParent: false,
      category: 'A',
      categoryOptions: [],
      disableCategorySelectionField: false,
      consolidateAccount: null,
      bankAccountStatus: false,
      disableBankAccountStatus: true,
      errors: null,
      errorsResponse: null,
      // no validation submit
      displayNoValidationSubmit: false,
      noValidationSubmit: false
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.mapOptions()
      if (this.rowSelected) {
        this.modalSetup()
      }
    },
    mapOptions() {
      this.typeOptions = this.typeOptionsProps
      this.levelOptions = this.levelOptionsProps
      this.categoryOptions = this.categoryOptionsProps
    },
    modalSetup() {
      this.isLoading = true
      let row = this.rowSelected
      if (row.glmaccn_accountType == 'สรุปยอด') {
        this.disableBankAccountStatus = true
        this.accountType = 1
      } else {
        this.disableBankAccountStatus = false
        this.accountType = 2
      }
      this.id = row.glmaccn_id
      this.accountNumber = row.glmaccn_accountNumber
      this.accountName = row.glmaccn_accountName
      this.accountLevel = row.glmaccn_accountLevel
      this.parent = row.glmaccn_parentAccount
      this.category = this.categoryOptions.filter(
        (el) => el.text === row.glmaccn_group
      )[0].value
      this.consolidateAccount = row.glmaccn_consolidateAccount
      this.bankAccountStatus = row.glmaccn_rcsi == '-' ? false : true
      this.isLoading = false
    },
    async updateButtonClicked() {
      this.errorsResponse = null

      let payload = {
        id: this.id,
        accountNumber: this.accountNumber,
        accountType: this.typeOptions.find(
          (el) => +el.value === +this.accountType
        ).enum,
        accountName: this.accountName,
        accountLevel: this.accountLevel,
        parentAccount: this.parent,
        group: this.categoryOptions.find((el) => el.value === this.category)
          .enum,
        consolidateAccount:
          this.consolidateAccount && this.consolidateAccount.trim().length > 0
            ? this.consolidateAccount.trim()
            : null,
        bank: this.bankAccountStatus
      }
      if (!this.validateData(payload)) return

      try {
        this.isLoading = true
        if (this.noValidationSubmit) {
          await ge1mastService.updateGe1mastById({
            ...payload,
            skipValidation: true
          })
          this.noValidationSubmit = false
          this.displayNoValidationSubmit = false
        } else {
          await ge1mastService.updateGe1mastById(payload)
        }
        this.$emit('closeModal', {
          from: 'updateSuccess',
          content: 'แก้ไขรหัสบัญชีสำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        this.displayNoValidationSubmit = true
        this.errorsResponse = err.response.data
        console.log('Update ledger error!', err)
        this.$emit('closeModal', {
          from: 'updateError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 1000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    validateData(args) {
      this.errors = null
      const errors = {}

      // accountName
      if (!args.accountName || args.accountName.trim().length <= 0) {
        errors.accountName = 'จำเป็นต้องใส่ ชื่อบัญชี'
      } else if (!validator.isLength(args.accountName, { min: 1, max: 50 })) {
        errors.accountName = 'ชื่อบัญชี ไม่สามารถใส่เกิน 50 ตัวอักษรได้'
      }

      // csac (consolidateAccount)
      if (
        !(
          _.isNull(args.consolidateAccount) ||
          args.consolidateAccount === '' ||
          (_.isString(args.consolidateAccount) &&
            !_.isNaN(+args.consolidateAccount) &&
            validator.isInt(args.consolidateAccount, {
              mix: 1,
              max: 999999999999
            }))
        )
      ) {
        errors.consolidateAccount =
          'ใส่ตัวเลขเท่านั้น และ ไม่สามารถใส่เกิน 12 หลักได้'
      }

      // if errors !== {} then not pass
      if (Object.keys(errors).length > 0) {
        this.errors = errors
        return false
      }
      return true
    }
  }
}
</script>

<template>
  <div>
    <!-- row not selected -->
    <div v-if="!rowSelected">
      <span>กรุณาเลือกรายการบัญชีที่ต้องการแก้ไข</span>
    </div>

    <!-- row selected -->
    <div v-else>
      <!-- accountNumber -->
      <div class="mb-3 d-flex align-items-center">
        <label class="mr-4" style="width: 130px">เลขบัญชี</label>
        <b-form-input
          v-model="accountNumber"
          :disabled="disableAccountNumberInputField"
          trim
        ></b-form-input>
      </div>

      <!-- accountName -->
      <div class="mb-3 d-flex align-items-center">
        <label class="mr-4" style="width: 130px">ชื่อบัญชี</label>
        <div class="w-100">
          <b-form-input v-model="accountName" trim></b-form-input>
          <span
            class="pl-1 text-danger error-style"
            v-if="errors && errors.accountName"
          >
            {{ errors.accountName }}
          </span>
        </div>
      </div>

      <!-- accountType -->
      <div class="d-flex mb-3">
        <label class="mr-4" style="width: 130px">ชนิดบัญชี</label>
        <b-form-radio-group
          v-model="accountType"
          :options="typeOptions"
          value-field="value"
          text-field="text"
          disabled-field="notEnabled"
          :disabled="disableTypeSelectionField"
        ></b-form-radio-group>
      </div>

      <!-- accountLevel -->
      <div class="d-flex align-items-center mb-3">
        <label class="mr-4" style="width: 130px">บัญชีระดับ</label>
        <b-form-select
          v-model="accountLevel"
          :options="levelOptions"
          disabled
        ></b-form-select>
      </div>

      <!-- parent -->
      <div class="d-flex align-items-center mb-3">
        <label class="mr-4" style="width: 130px">บัญชีแม่</label>
        <b-form-input
          v-model="parent"
          :disabled="disableParent"
          trim
        ></b-form-input>
      </div>

      <!-- category -->
      <div class="d-flex align-items-center mb-3">
        <label class="mr-4" style="width: 130px">หมวดบัญชี</label>
        <b-form-select
          v-model="category"
          :options="categoryOptions"
          :disabled="disableCategorySelectionField"
        ></b-form-select>
      </div>

      <!-- consolidateAccount -->
      <div class="d-flex align-items-center mb-3">
        <label class="mr-4" style="width: 130px">เลขบัญชีกลาง</label>
        <div class="w-100">
          <b-form-input v-model="consolidateAccount" trim></b-form-input>
          <span
            class="pl-1 text-danger error-style"
            v-if="errors && errors.consolidateAccount"
          >
            {{ errors.consolidateAccount }}
          </span>
        </div>
      </div>

      <div v-if="!disableBankAccountStatus">
        <hr />
        <b-form-checkbox v-model="bankAccountStatus" switch>
          บัญชีธนาคาร
        </b-form-checkbox>
      </div>

      <!-- error message from response -->
      <div v-if="errorsResponse" class="my-3">
        <b-icon
          icon="exclamation-triangle-fill"
          variant="danger"
          class="mr-2"
          font-scale="1.5"
        ></b-icon>
        <span class="text-danger font-weight-bold">
          {{ errorsResponse.thMessage || errorsResponse.message }}
        </span>
      </div>

      <!-- buttons -->
      <hr />
      <b-row align-h="end">
        <b-col v-if="displayNoValidationSubmit" class="col-6">
          <b-button
            class="w-100"
            variant="danger"
            @click="
              noValidationSubmit = true
              updateButtonClicked()
            "
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>บันทึกแบบไม่สนเงื่อนไข</span>
          </b-button>
        </b-col>
        <b-col class="col-3">
          <b-button
            class="w-100"
            variant="primary"
            @click="updateButtonClicked()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>บันทึก</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped>
.error-style {
  font-size: 12px;
}
</style>
