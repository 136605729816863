<script>
import CreateModal from './CreateModal.vue'
import ReadModal from './ReadModal.vue'
import DeleteModal from './DeleteModal.vue'
import UpdateModal from './UpdateModal.vue'
import ExportPdf from './ExportPdf.vue'

import ge1mastService from '@/services/master/ge1mast.js'
import { toastMixins } from '@/mixins/toast'

export default {
  mixins: [toastMixins],
  components: {
    CreateModal,
    ReadModal,
    UpdateModal,
    DeleteModal,
    ExportPdf
  },
  props: {
    rowSelected: Object
  },
  data() {
    return {
      buttonClass:
        'rounded-circle shadow m-1 ml-2 d-flex justify-content-center align-items-center',
      iconClass: 'icon-style',
      isModalShow: false,
      currentModal: null,
      modalThemeColor: null,
      modalTitle: null,
      exportPdfClicked: false,
      typeOptions: [],
      levelOptions: [],
      categoryOptions: [],
      user: null,
      errMsg: null
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      await Promise.all([
        this.fetchTypeOptions(),
        this.fetchLevelOptions(),
        this.fetchCategoryOptions()
      ])
      this.user = this.$store.state.auth.user
    },
    async fetchTypeOptions() {
      try {
        const res = await ge1mastService.getTypeOptions()
        this.typeOptions = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลตัวเลือกชนิดไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    async fetchLevelOptions() {
      try {
        const res = await ge1mastService.getLevelOptions()
        this.levelOptions = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลตัวเลือกระดับไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    async fetchCategoryOptions() {
      try {
        const res = await ge1mastService.getCategoryOptions()
        this.categoryOptions = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลตัวเลือกประเภทไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    buttonClicked(type) {
      switch (type) {
        case 'REFRESH':
          location.reload()
          break
        case 'CREATE':
          this.currentModal = 'create-modal'
          this.modalThemeColor = 'success'
          this.modalTitle = 'สร้างรหัสบัญชี'
          this.isModalShow = true
          break
        case 'READ':
          this.currentModal = 'read-modal'
          this.modalThemeColor = 'warning'
          this.modalTitle = 'ข้อมูลประวัติบัญชี'
          this.isModalShow = true
          break
        case 'UPDATE':
          this.currentModal = 'update-modal'
          this.modalThemeColor = 'primary'
          this.modalTitle = 'แก้ไขรหัสบัญชี'
          this.isModalShow = true
          break
        case 'DELETE':
          this.currentModal = 'delete-modal'
          this.modalThemeColor = 'danger'
          this.modalTitle = 'ลบรายการบัญชี'
          this.isModalShow = true
          break
        case 'EXPORT':
          this.exportPdfClicked = true
          break
        case 'LOGOUT':
          this.$router.push({ name: 'Home' })
          break
      }
    },
    closeModalHandler(payload) {
      if (payload.from.includes('Success') || payload.from.includes('Cancel')) {
        this.isModalShow = false
      }
      if (payload && !['deleteCancel'].includes(payload.from)) {
        this.$bvToast.toast(payload.content, payload.options)
      }
      if (
        payload &&
        !['createError', 'updateError', 'deleteError', 'deleteCancel'].includes(
          payload.from
        )
      ) {
        this.$emit('updateTable')
      }
    },
    reportGeneratedHandler() {
      this.exportPdfClicked = false
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-end">
      <!-- RESTART BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="info"
          @click="buttonClicked('REFRESH')"
          v-b-tooltip.hover.bottom.v-info="'โหลดหน้าใหม่'"
        >
          <b-icon :class="iconClass" icon="arrow-repeat"></b-icon>
        </b-button>
      </div>

      <!-- CREATE BUTTON -->
      <div v-if="user && user.permission.registrationMaster.canAddNew">
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('CREATE')"
          v-b-tooltip.hover.bottom.v-success="'เพิ่มรหัสบัญชี'"
        >
          <b-icon :class="iconClass" icon="plus"></b-icon>
        </b-button>
      </div>

      <!-- EDIT BUTTON -->
      <div v-if="user && user.permission.registrationMaster.canUpdate">
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขรหัสบัญชี'"
        >
          <b-icon :class="iconClass" icon="pencil-square"></b-icon>
        </b-button>
      </div>

      <!-- DELETE BUTTON -->
      <div v-if="user && user.permission.registrationMaster.canDelete">
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบรายการบัญชี'"
        >
          <b-icon :class="iconClass" icon="trash"></b-icon>
        </b-button>
      </div>

      <!-- VIEW BUTTON -->
      <div v-if="user && user.permission.registrationMaster.canView">
        <b-button
          :class="buttonClass"
          variant="warning"
          @click="buttonClicked('READ')"
          v-b-tooltip.hover.bottom.v-warning="'ดูข้อมูลประวัติบัญชี'"
        >
          <b-icon :class="iconClass" icon="eye"></b-icon>
        </b-button>
      </div>

      <!-- EXPORT BUTTON -->
      <div v-if="user && user.permission.registrationMaster.canPrint">
        <b-button
          :class="buttonClass"
          variant="info"
          @click="buttonClicked('EXPORT')"
          v-b-tooltip.hover.bottom.v-info="'ออกรายงานภาพรวมผังบัญชี'"
        >
          <b-icon :class="iconClass" icon="file-earmark-text"></b-icon>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('LOGOUT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon :class="iconClass" icon="box-arrow-right"></b-icon>
        </b-button>
      </div>

      <!-- MODAL -->
      <b-modal
        v-model="isModalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        header-text-variant="light"
        :hide-footer="true"
        :size="currentModal === 'read-modal' ? 'xl' : 'md'"
      >
        <component
          :is="currentModal"
          :rowSelected="rowSelected"
          @closeModal="closeModalHandler"
          :typeOptionsProps="typeOptions"
          :levelOptionsProps="levelOptions"
          :categoryOptionsProps="categoryOptions"
        ></component>
      </b-modal>
    </div>

    <!-- export pdf -->
    <div class="d-none">
      <export-pdf
        :click="exportPdfClicked"
        @reportGenerated="reportGeneratedHandler"
      ></export-pdf>
    </div>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style scoped>
.icon-style {
  width: 25px;
  height: 25px;
}
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
