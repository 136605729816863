<script>
import { toastMixins } from '@/mixins/toast'
import ge1mastService from '@/services/master/ge1mast.js'

export default {
  mixins: [toastMixins],
  props: {
    dataToExportProp: Object
  },
  data() {
    return {
      exportPdfData: null
    }
  },
  watch: {
    dataToExportProp() {
      this.assignPropToExportPdfData()
    }
  },
  created() {
    this.assignPropToExportPdfData()
  },
  methods: {
    assignPropToExportPdfData() {
      if (this.dataToExportProp) {
        this.exportPdfData = this.dataToExportProp
      }
    }
  }
}
</script>

<template>
  <div>
    <!-- P'Anuwat original code -->
    <!-- <div class="table-accn">
      <div class="header">ผังบัญชี</div>
      <table cellspacing="0">
        <thead>
          <tr>
          <th align="left" width="15%">เลขที่บัญชี</th>
          <th width="45%">ชื่อบัญชี</th>
          <th width="12%">ประเภทบัญชี</th>
          <th width="12%">กลุ่มบัญชี</th>
          <th width="8%">ระดับ</th>
          <th width="8%">ธนาคาร</th>
          </tr>
        </thead>
        <tbody>
          @forelse ($ledgers["rows"] as $row)
            <tr>
              <td style='text-align:left;'>&nbsp;&nbsp;{{$row->glm_acno}}</td>
              <td style='text-align:left'>{{$row->glm_acna}}</td>
              <td>{{$row->glm_type}}</td>
              <td>{{$row->glm_group}}</td>
              <td>{{$row->glm_aclv}}</td>
              <td>{{$row->glm_bank}}</td>
            </tr>
          @empty
            <tr><td colspan='5'>Error</td></tr>
          @endforelse
          <tfoot>
            <tr>
            <td colspan="3">รวมรายชื่อบัญชีทั้งหมด</td>
            <td>{{$ledgers["totalRows"]}}</td>
            <td colspan="2">รายการ</td>
            </tr>
          </tfoot>
        </tbody>
      </table>
    </div> -->

    <div>
      <div class="header">ผังบัญชี</div>
      <table cellspacing="0">
        <thead>
          <tr>
            <th align="left" width="15%">เลขที่บัญชี</th>
            <th width="45%">ชื่อบัญชี</th>
            <th width="12%">ประเภทบัญชี</th>
            <th width="12%">กลุ่มบัญชี</th>
            <th width="8%">ระดับ</th>
            <th width="8%">ธนาคาร</th>
          </tr>
        </thead>
        <tbody v-if="exportPdfData">
          <tr v-for="(row, index) in exportPdfData.rows" :key="row.glmaccn_id">
            <td style="text-align: left; padding: 3px 0px 3px 2px;">
              &nbsp;&nbsp;{{ row.glmaccn_accountNumber }}
            </td>
            <td style="text-align: left;">{{ row.glmaccn_accountName }}</td>
            <td>{{ row.glmaccn_accountType }}</td>
            <td>{{ row.glmaccn_group }}</td>
            <td>{{ row.glmaccn_accountLevel }}</td>
            <td>{{ row.glmaccn_bank }}</td>
            <div v-if="index + 1 === 34" class="html2pdf__page-break" />
            <div
              v-if="index + 1 > 34 && (index + 1 - 34) % 36 === 0"
              class="html2pdf__page-break"
            />
          </tr>
          <tr>
            <td colspan="3">รวมรายชื่อบัญชีทั้งหมด</td>
            <td>{{ exportPdfData.totalRows }}</td>
            <td colspan="2">รายการ</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.header {
  background-color: #327a81;
  color: white;
  font-size: 1.5em;
  /* padding: 1rem; */
  text-align: center;
  text-transform: uppercase;
}
img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
.table-accn {
  border: 1px solid #327a81;
  border-radius: 10px;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 2em);
  margin: 1em auto;
  overflow: hidden;
  width: 800px;
}
.padleft {
  margin-left: 5px;
}
table {
  width: 100%;
}
table td,
table th {
  color: #444;
  /* padding: 10px; */
  font-size: 1em;
}
table td {
  text-align: center;
  vertical-align: middle;
}
table td:last-child {
  /* font-size: 0.95em; */
  line-height: 1.4;
  text-align: center;
}
table th {
  background-color: #9bd4d9;
  font-weight: 300;
}
table tr:nth-child(2n) {
  background-color: white;
}
table tr:nth-child(2n + 1) {
  background-color: #edf7f8;
}

@media screen and (max-width: 700px) {
  table,
  tr,
  td {
    display: block;
  }
  td:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
  }
  td:not(:first-child) {
    clear: both;
    margin-left: 100px;
    padding: 4px 20px 4px 90px;
    position: relative;
    text-align: center;
  }
  td:not(:first-child):before {
    color: #91ced4;
    content: '';
    display: block;
    left: 0;
    position: absolute;
  }
  td:nth-child(2):before {
    content: 'Name:';
  }
  td:nth-child(3):before {
    content: 'Email:';
  }
  td:nth-child(4):before {
    content: 'Phone:';
  }
  td:nth-child(5):before {
    content: 'Comments:';
  }
  tr {
    padding: 10px 0;
    position: relative;
  }
  tr:first-child {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .header {
    background-color: transparent;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    padding: 0;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
  img {
    border: 3px solid;
    border-color: #daeff1;
    height: 100px;
    margin: 0.5rem 0;
    width: 100px;
  }
  td:first-child {
    background-color: #c8e7ea;
    border-bottom: 1px solid #91ced4;
    border-radius: 10px 10px 0 0;
    position: relative;
    top: 0;
    transform: translateY(0);
    width: 100%;
  }
  td:not(:first-child) {
    margin: 0;
    padding: 5px 1em;
    width: 100%;
  }
  td:not(:first-child):before {
    font-size: 0.8em;
    padding-top: 0.3em;
    position: relative;
  }
  td:last-child {
    padding-bottom: 1rem !important;
  }
  tr {
    background-color: white !important;
    border: 1px solid #6cbec6;
    border-radius: 10px;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin: 0.5rem 0;
    padding: 0;
  }
  .table-accn {
    border: none;
    box-shadow: none;
    overflow: visible;
  }
}
</style>
