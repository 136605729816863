<script>
import validator from 'validator'
import _ from 'lodash'

import ge1mastService from '@/services/master/ge1mast.js'

export default {
  props: {
    rowSelected: Object,
    typeOptionsProps: Array,
    levelOptionsProps: Array,
    categoryOptionsProps: Array
  },
  data() {
    return {
      isLoading: false,
      accountNumber: null,
      accountName: null,
      accountType: 1,
      typeOptions: [],
      accountLevel: 1,
      levelOptions: [],
      disableLevel: true,
      parent: null,
      parentId: null,
      disableParent: true,
      category: 'A',
      categoryOptions: [],
      disableCategory: false,
      consolidateAccount: null,
      bankAccountStatus: false,
      errors: null,
      errorsResponse: null
    }
  },
  watch: {
    accountType() {
      if (this.accountType == 1) this.bankAccountStatus = false
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.mapChoiceOptions()
      this.modalSetup()
    },
    mapChoiceOptions() {
      this.typeOptions = this.typeOptionsProps
      this.levelOptions = this.levelOptionsProps
      this.categoryOptions = this.categoryOptionsProps
    },
    modalSetup() {
      if (this.rowSelected) {
        let parentRow = null
        if (this.rowSelected.glmaccn_accountType === 'รายละเอียด') {
          parentRow = this.rowSelected.parent
        } else {
          parentRow = this.rowSelected
        }
        this.accountLevel = parentRow.glmaccn_accountLevel + 1
        this.parent = parentRow.glmaccn_accountNumber
        this.parentId = parentRow.glmaccn_id
        this.category = this.categoryOptions.filter(
          category => category.text === parentRow.glmaccn_group
        )[0].value
        this.disableCategory = true
      } else {
        this.disableCategory = false
      }
    },
    async saveButtonClicked() {
      this.errorsResponse = null

      let body = {
        accountNumber: this.accountNumber,
        accountType: this.typeOptions.find(
          el => +el.value === +this.accountType
        ).enum,
        accountName: this.accountName,
        accountLevel: this.accountLevel,
        parentAccount: this.parent,
        group: this.categoryOptions.find(el => el.value === this.category).enum,
        consolidateAccount:
          this.consolidateAccount && this.consolidateAccount.trim().length > 0
            ? this.consolidateAccount.trim()
            : null,
        bank: this.bankAccountStatus
      }
      if (!this.checkValidationRule(body)) return

      try {
        this.isLoading = true
        await ge1mastService.createGe1mast(body)
        this.$emit('closeModal', {
          from: 'createSuccess',
          content: 'สร้างรหัสบัญชีใหม่สำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        this.errorsResponse = err.response.data
        console.log('=== Save new ledger error ===', err)
        this.$emit('closeModal', {
          from: 'createError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 1000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    checkValidationRule(args) {
      this.errors = null
      const errors = {}

      // code (accountNumber)
      if (!args.accountNumber || args.accountNumber.trim().length <= 0) {
        errors.accountNumber = 'จำเป็นต้องใส่ เลขบัญชี'
      } else if (
        !validator.isInt(args.accountNumber, { min: 1, max: 999999999 })
      ) {
        errors.accountNumber =
          'ใส่ตัวเลขเท่านั้น และ ไม่สามารถใส่เกิน 9 หลักได้'
      }

      // accountName (accountName)
      if (!args.accountName || args.accountName.trim().length <= 0) {
        errors.accountName = 'จำเป็นต้องใส่ ชื่อบัญชี'
      } else if (!validator.isLength(args.accountName, { mix: 1, max: 50 })) {
        errors.accountName = 'ชื่อบัญชี ไม่สามารถใส่เกิน 50 ตัวอักษรได้'
      }

      // csac (consolidateAccount)
      if (
        !(
          _.isNull(args.consolidateAccount) ||
          args.consolidateAccount === '' ||
          (_.isString(args.consolidateAccount) &&
            !_.isNaN(+args.consolidateAccount) &&
            validator.isInt(args.consolidateAccount, {
              mix: 1,
              max: 999999999999
            }))
        )
      ) {
        errors.consolidateAccount =
          'ใส่ตัวเลขเท่านั้น และ ไม่สามารถใส่เกิน 12 หลักได้'
      }

      // if errors !== {} then not pass
      if (Object.keys(errors).length > 0) {
        this.errors = errors
        return false
      }
      return true
    }
  }
}
</script>

<template>
  <div>
    <!-- accountNumber -->
    <div class="mb-3 d-flex align-items-center">
      <label class="mr-4" style="width: 130px;">เลขบัญชี</label>
      <div class="w-100">
        <b-form-input v-model="accountNumber" trim></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.accountNumber"
        >
          {{ errors.accountNumber }}
        </span>
      </div>
    </div>

    <!-- accountName -->
    <div class="mb-3 d-flex align-items-center">
      <label class="mr-4" style="width: 130px;">ชื่อบัญชี</label>
      <div class="w-100">
        <b-form-input v-model="accountName" trim></b-form-input>

        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.accountName"
        >
          {{ errors.accountName }}
        </span>
      </div>
    </div>

    <!-- accountType -->
    <div class="d-flex mb-3">
      <label class="mr-4" style="width: 130px;">ชนิดบัญชี</label>
      <b-form-radio-group
        v-model="accountType"
        :options="typeOptions"
        value-field="value"
        text-field="text"
        disabled-field="notEnabled"
      ></b-form-radio-group>
    </div>

    <!-- accountLevel -->
    <div class="d-flex align-items-center mb-3">
      <label class="mr-4" style="width: 130px;">บัญชีระดับ</label>
      <b-form-select
        v-model="accountLevel"
        :options="levelOptions"
        :disabled="disableLevel"
      ></b-form-select>
    </div>

    <!-- parent -->
    <div class="d-flex align-items-center mb-3">
      <label class="mr-4" style="width: 130px;">บัญชีแม่</label>
      <b-form-input
        v-model="parent"
        :disabled="disableParent"
        trim
      ></b-form-input>
    </div>

    <!-- category -->
    <div class="d-flex align-items-center mb-3">
      <label class="mr-4" style="width: 130px;">หมวดบัญชี</label>
      <b-form-select
        v-model="category"
        :options="categoryOptions"
        :disabled="disableCategory"
      ></b-form-select>
    </div>

    <!-- consolidateAccount -->
    <div class="d-flex align-items-center mb-3">
      <label class="mr-4" style="width: 130px;">เลขบัญชีกลาง</label>
      <div class="w-100">
        <b-form-input v-model="consolidateAccount" trim></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.consolidateAccount"
        >
          {{ errors.consolidateAccount }}
        </span>
      </div>
    </div>

    <hr v-if="accountType == 2" />
    <b-form-checkbox v-if="accountType == 2" v-model="bankAccountStatus" switch>
      บัญชีธนาคาร
    </b-form-checkbox>

    <!-- error message from response -->
    <div v-if="errorsResponse" class="my-3">
      <b-icon
        icon="exclamation-triangle-fill"
        variant="danger"
        class="mr-2"
        font-scale="1.5"
      ></b-icon>
      <span class="text-danger font-weight-bold">
        {{ errorsResponse.thMessage || errorsResponse.message }}
      </span>
    </div>

    <!-- buttons -->
    <hr />
    <b-row align-h="end">
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="success"
          @click="saveButtonClicked()"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>บันทึก</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.error-style {
  font-size: 12px;
}
</style>
