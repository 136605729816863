<script>
import VueHtml2pdf from 'vue-html2pdf'

import ge1mastService from '@/services/master/ge1mast.js'

import PdfContent from './PdfContent.vue'

export default {
  props: {
    click: Boolean
  },
  components: {
    VueHtml2pdf,
    PdfContent
  },
  data() {
    return {
      exportPdfData: {
        rows: [],
        totalRows: 0
      },
      errMsg: null,
      dataToExport: {}
    }
  },
  watch: {
    async click() {
      if (this.click) {
        await this.fetchExportPdfData()
        this.generateReport()
        this.$emit('reportGenerated')
      }
    }
  },
  methods: {
    generateReport() {
      /**
        Generate Report using refs and calling the
        refs function generatePdf()
      */
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(e) {
      // console.log('onProgress');
      // console.log(e);
    },
    hasStartedGeneration() {
      // console.log('hasStartedGeneration');
    },
    hasGenerated(e) {
      // console.log('hasGenerated');
      // console.log(e);
    },
    async fetchExportPdfData() {
      try {
        const res = await ge1mastService.getExportPdfData()
        this.dataToExport = { ...res.data.data }
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลสำหรับออกรายงานไม่สำเร็จ'
        this.errMsg = errMessage
      }
    }
  }
}
</script>

<template>
  <div>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="ภาพรวมของผังบัญชี"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        margin: 5,
        filename: 'ภาพรวมของผังบัญชี'
      }"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-content :dataToExportProp="dataToExport"></pdf-content>
      </section>
    </vue-html2pdf>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>
