<script>
import { toastMixins } from '@/mixins/toast'
import ge1mastService from '@/services/master/ge1mast.js'
import MenuBar from '@/views/master/ge1mast/components/MenuBar'

export default {
  mixins: [toastMixins],
  components: {
    MenuBar
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { type: 'glmaccn_accountNameExtra', title: 'ชื่อบัญชี', width: '55%' },
        { type: 'glmaccn_accountType', title: 'ชนิด', width: '10%' },
        { type: 'glmaccn_group', title: 'หมวด', width: '10%' },
        { type: 'glmaccn_accountLevel', title: 'ระดับ', width: '10%' },
        { type: 'glmaccn_rcsi', title: 'ธนาคาร', width: '15%' }
      ],
      ledgers: [],
      rowSelected: null,
      rowOpenCollections: [],
      count: 0,
      errMsg: null
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.fetchGe1mastTable()
    },
    async fetchGe1mastTable() {
      this.isLoading = true
      try {
        const results = await ge1mastService.getGe1mastTables()
        this.ledgers = results.data.data.ge1mastTable
        this.restoreLedgerOpenCloseState()
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลผังบัญชีไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    restoreLedgerOpenCloseState() {
      this.ledgers.forEach(ledger => {
        this.restoreLedgerOpenCloseStateRecursive(ledger)
      })
    },
    restoreLedgerOpenCloseStateRecursive(ledger) {
      if (this.rowOpenCollections.includes(ledger.glmaccn_id)) {
        ledger.state = 'open'
      }
      if (ledger.children.length > 0) {
        ledger.children.forEach(childLedger => {
          this.restoreLedgerOpenCloseStateRecursive(childLedger)
        })
      }
    },
    rowSelectedHandler(row) {
      this.rowSelected = row
    },
    rowExpandHandler(row) {
      const isSummaryAccount = row.glmaccn_accountType == 'สรุปยอด'
      const noChildren = row.children.length <= 0
      if (!isSummaryAccount || !noChildren)
        this.rowOpenCollections.push(row.glmaccn_id)
    },
    rowCollapseHandler(row) {
      this.rowOpenCollections = this.rowOpenCollections.filter(
        el => el !== row.glmaccn_id
      )
    },
    updateTableHandler() {
      this.fetchGe1mastTable()
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <b-row align-v="center">
      <b-col class="col-12 col-lg-6 py-3 text-center text-lg-left">
        <span class="h2 text-white" style="text-shadow: 2px 2px grey;">
          Table of Accounts / ผังบัญชี
        </span>
      </b-col>
      <b-col
        class="col-12 col-lg-6 py-3 d-flex justify-content-center justify-content-lg-end"
      >
        <menu-bar
          :rowSelected="rowSelected"
          @updateTable="updateTableHandler"
        ></menu-bar>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="isLoading">
        <div
          class="d-flex justify-content-center align-items-center bg-light p-5"
        >
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <span class="ml-2">กำลังโหลดข้อมูล ...</span>
        </div>
      </b-col>
      <b-col v-else>
        <TreeGrid
          style="height: 457px"
          :data="ledgers"
          idField="glmaccn_id"
          treeField="glmaccn_accountNameExtra"
          @rowSelect="rowSelectedHandler"
          @rowExpand="rowExpandHandler"
          @rowCollapse="rowCollapseHandler"
        >
          <GridColumn
            v-for="field in fields"
            :key="field.type"
            :field="field.type"
            :title="field.title"
            :width="field.width"
          ></GridColumn>
        </TreeGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
