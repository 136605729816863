<template>
  <div>
    <b-row>
      <b-col class="my-2 col-12">
        <label class="the-fixed-width">เลขบัญชี</label>
        <span class="font-weight-bold">
          [{{ `${this.rowSelected.glmaccn_accountNumber} ` }}] - [{{
            `${this.rowSelected.glmaccn_accountName} `
          }}]
        </span>
      </b-col>
    </b-row>

    <div class="the-hover-line"></div>

    <b-row>
      <b-col class="col-12 col-lg-6 my-2 my-lg-2">
        <label class="the-fixed-width">ประเภทบัญชี</label>
        <span class="font-weight-bold">{{
          this.rowSelected.glmaccn_accountType
        }}</span>
      </b-col>
      <b-col class="col-12 col-lg-6 my-0 mb-2 my-lg-2">
        <label class="the-fixed-width">กลุ่มบัญชี</label>
        <span class="font-weight-bold">{{
          this.rowSelected.glmaccn_group
        }}</span>
      </b-col>
    </b-row>

    <div class="the-hover-line"></div>

    <b-row>
      <b-col class="col-12 col-lg-6 my-2 my-lg-2">
        <label class="the-fixed-width">ระดับบัญชี</label>
        <span class="font-weight-bold">{{
          this.rowSelected.glmaccn_accountLevel
        }}</span>
      </b-col>
      <b-col class="col-12 col-lg-6 my-0 mb-2 my-lg-2">
        <label class="the-fixed-width">{{ twoYearsTitle }}</label>
        <span class="font-weight-bold">{{
          mxNumeralThousandSeperate(twoYearsValue)
        }}</span>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="col-12" style="overflow-x: auto;">
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr class="table-warning">
              <th
                v-for="(field, index) in fields"
                :key="index"
                :class="
                  index == 0
                    ? 'text-center smallWidth'
                    : 'text-right largeWidth'
                "
              >
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td
                :class="index == 0 || index == 13 ? 'font-weight-bold' : null"
                class="text-center"
              >
                {{ item.month }}
              </td>
              <td
                :class="index == 0 || index == 13 ? 'font-weight-bold' : null"
                class="text-right"
              >
                {{ mxNumeralThousandSeperate(item.past) }}
              </td>
              <td
                :class="index == 0 || index == 13 ? 'font-weight-bold' : null"
                class="text-right"
              >
                {{ mxNumeralThousandSeperate(item.present1) }}
              </td>
              <td
                :class="index == 0 || index == 13 ? 'font-weight-bold' : null"
                class="text-right"
              >
                {{ mxNumeralThousandSeperate(item.present2) }}
              </td>
              <td
                :class="index == 0 || index == 13 ? 'font-weight-bold' : null"
                class="text-right"
              >
                {{ mxNumeralThousandSeperate(item.future) }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<script>
import { numberMixins, toastMixins } from '@/mixins'
import ge1mastService from '@/services/master/ge1mast.js'

export default {
  mixins: [numberMixins, toastMixins],
  props: {
    rowSelected: Object
  },
  data() {
    return {
      fields: [
        { key: 'month', label: null, thClass: 'smallWidth' },
        { key: 'past', label: null, thClass: 'largeWidth' },
        { key: 'present1', label: null, thClass: 'largeWidth' },
        { key: 'present2', label: null, thClass: 'largeWidth' },
        { key: 'future', label: null, thClass: 'largeWidth' }
      ],
      items: [],
      errMsg: null
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    twoYearsTitle() {
      if (this.items.length > 0) {
        let firstYear = this.fields[3].label.split(' ')[1]
        let secondYear = this.fields[4].label.split(' ')[1]
        return `รวม(${firstYear} + ${secondYear})`
      }
      return 'รวม'
    },
    twoYearsValue() {
      if (this.items.length > 0) {
        return this.items[this.items.length - 1].future
      }
      return '-'
    }
  },
  methods: {
    initialize() {
      this.fetchGe1mastTableById(this.rowSelected.glmaccn_id)
    },
    async fetchGe1mastTableById(ledgerId) {
      try {
        this.items = []
        let res = await ge1mastService.getGe1mastTableById(ledgerId)
        let data = { ...res.data.data }
        for (let index in data.fields)
          this.fields[index].label = data.fields[index]
        let resources = [
          data.items.header,
          ...data.items.detail,
          data.items.footer
        ]
        for (let resource of resources) {
          this.items.push({
            month: resource[0],
            past: resource[1],
            present1: resource[2],
            present2: resource[3],
            future: resource[4]
          })
        }
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลผังบัญชีไม่สำเร็จ'
        this.errMsg = errMessage
      }
    }
  }
}
</script>

<style scoped>
.the-fixed-width {
  width: 150px;
}
.the-hover-line {
  border: 1px solid grey;
}
.smallWidth {
  width: 15%;
  min-width: 100px;
}
.largeWidth {
  width: 21.25%;
  min-width: 150px;
}
</style>
